
import { Vue, Component } from "vue-property-decorator";
import Score from "@/components/feedback/components/Score.vue";
import { UFFeedback } from "@/utils/literals/feedback";

@Component({
  components: { Score },
})
export default class Examples extends Vue {
  exampleGroupScore = 70;
  ex1Scores = [
    {
      user: "User1",
      score: 32,
    },
    {
      user: "User2",
      score: 50,
    },
    {
      user: "User3",
      score: 65,
    },
    {
      user: "User4",
      score: 40,
    },
    {
      user: "User5",
      score: 12,
    },
  ];
  ex2Scores = [
    {
      user: "User1",
      score: 32,
    },
    {
      user: "User2",
      score: 50,
    },
    {
      user: "User3",
      score: 65,
    },
    {
      user: "User4",
      score: 40,
    },
    {
      user: "User5",
      score: 96,
    },
  ];

  getUFFb(key: string): string {
    return UFFeedback[key] || "";
  }
}
