
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "OneByOneComponentRenderer",
  emits: ["lastIndexReached"],
})
export default class OneByOneComponentRenderer extends Vue {
  @Prop({ required: true, default: [] })
  components!: (Vue.Component | Vue.Component[])[];

  currentComponentIndex = 0;

  @Watch("currentComponentIndex")
  lastIndexReached(val: number): void {
    if (val >= this.components.flat().length - 1) {
      this.$emit("lastIndexReached", { reached: true });
      return;
    }
    this.$emit("lastIndexReached", { reached: false });
  }

  get hiddenIndex(): number[] {
    let hiddenIndexes: number[] = [];
    let flatenIndex = 0;
    this.components.forEach(
      (item: Vue.Component | Vue.Component[], ix: number) => {
        if (Array.isArray(item)) {
          item.forEach((_, idx) => {
            hiddenIndexes.push(flatenIndex + idx);
          });
          hiddenIndexes.pop();
          flatenIndex = ix + item.length;
        } else {
          flatenIndex += 1;
        }
      }
    );
    return hiddenIndexes;
  }

  // Determine if a component at a given index should be visible
  isComponentVisible(index: number): boolean {
    if (
      this.hiddenIndex.includes(index) &&
      this.currentComponentIndex !== index
    ) {
      return false;
    }
    return this.currentComponentIndex >= index;
  }

  // Calculate the current components based on the nested array
  get currentComponents(): Vue.Component[] {
    const components: Vue.Component[] = [];
    this.components.forEach((item) => {
      if (Array.isArray(item)) {
        components.push(...item);
      } else {
        components.push(item);
      }
    });
    return components;
  }

  mounted(): void {
    document.addEventListener("keydown", this.handleKeyPress);
  }

  beforeDestroy(): void {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress(event: KeyboardEvent): void {
    switch (event.key) {
      case "ArrowRight":
      case "ArrowUp":
        this.incrementIndex();
        break;
      case "ArrowLeft":
      case "ArrowDown":
        this.decrementIndex();
        break;
      default:
        break;
    }
  }

  incrementIndex(): void {
    if (
      this.currentComponentIndex <
      (this.components ? this.components.flat().length - 1 : 0)
    ) {
      this.currentComponentIndex++;
    }
  }

  decrementIndex(): void {
    if (this.currentComponentIndex > 0) {
      this.currentComponentIndex--;
    }
  }
}
