
import { Vue, Component } from "vue-property-decorator";
import Score from "@/components/feedback/components/Score.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import PieChart from "@/components/charts/PieChart.vue";
import { FeedbackType } from "@/schemas/Enums";

@Component({
  name: "Results",
  components: { PieChart, Score },
  computed: {
    ...mapState("session", ["session"]),
  },
  methods: {
    ...mapActions("session", ["getSessionWordNumber"]),
  },
})
export default class Results extends Vue {
  participation = [];
  fullyLoaded = false;
  usernames = [];

  created(): void {
    // this.getSessionParticipation({
    //   sessionId: this.$route.params.sessionId,
    //   next: (participation) => {
    //     this.participation = participation.map((part) => part.participation);
    //     this.usernames = participation.map((part) => part.username);
    //     this.fullyLoaded = true;
    //   },
    // });
    this.getSessionWordNumber({
      sessionId: this.$route.params.sessionId,
      next: (participation) => {
        this.participation = participation.map((part) => part.participation);
        this.usernames = participation.map((part) => part.username);
        this.fullyLoaded = true;
      },
    });
  }

  get partPropOld(): number[] {
    let sum = 0;
    this.participation.forEach((num) => {
      sum += num;
    });
    return [
      ...this.participation.map((part) => Math.round((part * 100) / sum)),
    ];
  }

  get partProp(): number[] {
    return [
      ...this.participation,
    ];
  }

  get anonymized(): boolean {
    let setting = this.session.setting.feedback_details.find(
      (fb) => fb.type === FeedbackType.Participation
    );
    return !setting.display_score;
  }
}
