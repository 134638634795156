
import { Vue, Component } from "vue-property-decorator";
import Score from "@/components/feedback/components/Score.vue";
import { feedbackLiterals } from "@/utils/literals/feedback";
import { mapGetters, mapState } from "vuex";
import { FeedbackType } from "@/schemas/Enums";

@Component({
  components: { Score },
  computed: {
    ...mapGetters("session", ["getIndividualScores", "getGroupScore"]),
    ...mapState("session", ["session"]),
  },
})
export default class Conclusion extends Vue {
  get displayFeedbackMessage(): boolean {
    if (this.session.setting) {
      let settings = this.session.setting.feedback_details.find(
        (setting: {
          type: FeedbackType;
          display_feedback_message: boolean;
          display_score?: boolean;
          display_indiv_scores?: boolean;
          display_group_score?: boolean;
        }) => setting.type === FeedbackType.Performance
      );
      return settings ? settings.display_feedback_message : false;
    }
    return false;
  }

  /**
   * Return feedback conditional message
   */
  get feedbackMessage(): string {
    if (
      this.getIndividualScores.every(
        (score) => score.score <= this.getGroupScore
      )
    ) {
      return feedbackLiterals.message[0];
    }
    if (
      this.getIndividualScores.every(
        (score) => score.score >= this.getGroupScore
      )
    ) {
      return feedbackLiterals.message[1];
    }
    let nbIndividualHigherThanGroup = this.getIndividualScores.filter(
      (score) => score.score >= this.getGroupScore
    ).length;
    let nbIndividualLowerThanGroup = this.getIndividualScores.filter(
      (score) => score.score < this.getGroupScore
    ).length;
    let majority = Math.ceil(this.getIndividualScores.length / 2);
    if (nbIndividualHigherThanGroup >= majority) {
      if (nbIndividualHigherThanGroup === nbIndividualLowerThanGroup) {
        return feedbackLiterals.message[2];
      }
      return feedbackLiterals.message[3];
    }
    return feedbackLiterals.message[4];
  }
}
