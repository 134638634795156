
import { Vue, Component } from "vue-property-decorator";
import Intro from "@/components/feedback/participation/Intro.vue";
import Headline from "@/components/feedback/participation/Headline.vue";
import Examples from "@/components/feedback/participation/Examples.vue";
import ExamplesSuite from "@/components/feedback/participation/ExamplesSuite.vue";
import Conclusion from "@/components/feedback/participation/Conclusion.vue";
import OneByOneComponentRenderer from "@/components/OneByOneComponentRenderer.vue";
import Results from "@/components/feedback/participation/Results.vue";

@Component({
  components: {
    OneByOneComponentRenderer,
  },
})
export default class ParticipationFeedback extends Vue {
  feedbackComponents = [Intro, Headline, Examples, ExamplesSuite, Results, Conclusion];
}
