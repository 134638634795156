export const feedbackLiterals = {
  intro: `Si votre collaboration a été efficace, vous devriez obtenir un <strong>score d'équipe</strong> supérieur au score de chaque participant·e.`,
  message: [
    "Votre score d'équipe est supérieur au score de l’ensemble de vos participant·es." +
      "<br>Votre collaboration a été efficace." +
      "<br>Bravo !",
    "Votre score d'équipe est inférieur ou égal au score de l’ensemble de vos participant·es." +
      "<br>Votre collaboration n’a pas été efficace." +
      "<br>Continuez à vous entrainer.",
    "La moitié de vos participant·e ont un score supérieur à celui de l'équipe." +
      "<br>Votre collaboration n’a pas été efficace." +
      "<br>Continuez à vous entrainer.",
    "La majorité de vos participant·es ont un score supérieur ou égal à celui de votre équipe." +
      "<br>Votre collaboration n’a pas été efficace." +
      "<br>Continuez à vous entrainer.",
    "La majorité de vos participant·es ont un score inférieur au score de l'équipe." +
      "<br>Votre collaboration a été efficace, mais elle peut être encore améliorée." +
      "<br>Continuez comme ça.",
  ],
};

export const UFFeedback = {
  explain:
    "Afin d'obtenir vos résultats, un guide est proposé pour vous " +
    "aider à les interpréter.",
  intro:
    "Vous allez avoir un retour sur vos performances à la tâche de classement.",
  goal:
    "<b>Votre objectif est de produire un travail en équipe efficace.</b><br/> " +
    "C'est-à-dire qu'il faut obtenir un score en équipe supérieur à chacun de " +
      "scores obtenus par les participant·es qui composent l'équipe.",
  ex1:
    "Dans cet exemple, l'équipe obtient un score supérieur à chacun des participant·es\n" +
      "qui la compose.<br/>" +
    "<span class='font-weight-bold'>Le travail d'équipe a été le plus efficace, mais le score de l'équipe peut encore s'améliorer.</span>",
  ex2:
    "Dans cet exemple, un participant·e de l'équipe obtient un meilleur score que celui\n" +
      "obtenu en équipe.<br/>" +
    "<span class='font-weight-bold'>Le travail de l'équipe n'a pas été le plus efficace et le score de l'équipe peut encore s'améliorer.</span>",
  res: "Voici les résultats obtenus individuellement par les participant·es de votre équipe",
  res_gp:
    "Voici les résultats obtenus individuellement par les participant·es de votre équipe, avec votre score d'équipe",
  snack: "Utilisez les flèches de votre clavier pour naviguer sur le feedback.",
  result_btn: "Accéder à mes résultats",
  ex_btn: "Explication",
  user_icon_exp: "= Résultat de chaque participant·e",
  group_icon_exp: "= Résultat de l'équipe",
};
