
import { Vue, Component } from "vue-property-decorator";
import { UFFeedback } from "@/utils/literals/feedback";
import { assetsUrl } from "@/env";

@Component({})
export default class Intro extends Vue {
  getUFFb(key: string): string {
    return UFFeedback[key] || "";
  }
  getImgUrl(fileName: string): string {
    return `${assetsUrl}/svg/${fileName}`;
  }
}
