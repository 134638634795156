
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import clientCache from "@/utils/cacheUtils";
import { UFButtonText, UFliterals } from "@/utils/literals";
import { Component, Vue, Watch } from "vue-property-decorator";
import { EventType, FeedbackType } from "@/schemas/Enums";
import OneByOneComponentRenderer from "@/components/OneByOneComponentRenderer.vue";
import PerformanceFeedback from "@/views/feedback/PerformanceFeedback.vue";
import ParticipationFeedback from "@/views/feedback/ParticipationFeedback.vue";

@Component({
  name: "FeedbackPage",
  components: {
    ParticipationFeedback,
    PerformanceFeedback,
    OneByOneComponentRenderer,
  },
  methods: {
    ...mapActions("session", ["getSessionScore", "fetchSession"]),
    ...mapActions("event", ["createEvent"]),
    ...mapMutations("user", {
      setUserSessions: "SET_USER_SESSIONS",
    }),
    ...mapMutations({
      setSnack: "SET_SNACK",
    }),
  },
  computed: {
    FeedbackType() {
      return FeedbackType;
    },
    ...mapGetters("session", ["getIndividualScores", "getGroupScore"]),
    ...mapState("session", ["session"]),
  },
})
export default class FeedbackPage extends Vue {
  buttonMessage = "backToHomePage";
  displayRedirectButton = false;
  currentFeedback = undefined;
  fullyLoaded = false;
  displayBackToBeginningButton = false;

  @Watch("displayRedirectButton")
  displayRedirectButtonChanged(val: boolean): void {
    let feedbackSettings = this.session.setting.feedback_details;
    if (
      feedbackSettings.length > 1 &&
      this.currentFeedback === feedbackSettings[1].type
    ) {
      this.displayBackToBeginningButton = true;
    } else {
      this.displayBackToBeginningButton = false;
    }
  }

  created(): void {
    if (!this.session.setting) {
      this.fetchSession({
        sessionId: this.$route.params["sessionId"],
        next: () => this.setBaseData(),
      });
    } else {
      this.fullyLoaded = true;
      this.setBaseData();
    }
    this.getSessionScore({
      sessionId: this.$route.params["sessionId"],
    });
    this.createEvent({
      type: EventType.EnterFeedback,
      object: "feedback",
      session_id: this.$route.params["sessionId"],
    });
  }

  setBaseData(): void {
    let feedbackSettings = this.session.setting.feedback_details;
    if (feedbackSettings.length > 1) {
      this.currentFeedback = feedbackSettings[0].type;
      this.buttonMessage = "nextFeedback";
    } else {
      this.currentFeedback = feedbackSettings[0].type;
    }
    this.fullyLoaded = true;
  }

  redirectToBeginning(): void {
    let feedbackSettings = this.session.setting.feedback_details;
    this.currentFeedback = feedbackSettings[0].type;
    this.buttonMessage = "nextFeedback";
    this.displayRedirectButton = false;
  }

  redirect(): void {
    let feedbackSettings = this.session.setting.feedback_details;
    if (
      feedbackSettings.length > 1 &&
      this.currentFeedback === feedbackSettings[0].type
    ) {
      this.currentFeedback = feedbackSettings[1].type;
      this.buttonMessage = "backToHomePage";
      this.displayRedirectButton = false;
      return;
    }
    this.setUserSessions([]);
    clientCache.clear("items_to_be_ranked");
    clientCache.clear("ranked_items");
    clientCache.clear("agreed");
    clientCache.clear("end_requested");
    this.$router.push({ name: "home" });
  }

  getUFButton(key: string): string {
    return UFButtonText[key] || "";
  }

  beforeRouteLeave(): void {
    this.createEvent({
      type: EventType.LeaveFeedback,
      object: "feedback",
      session_id: this.$route.params["sessionId"],
    });
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }
}
