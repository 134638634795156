
import { Vue, Component } from "vue-property-decorator";
import { UFFeedback } from "@/utils/literals/feedback";
import { assetsUrl } from "@/env";
import ScorePin from "@/components/feedback/components/ScorePin.vue";

@Component({
  components: { ScorePin },
})
export default class Intro extends Vue {
  getUFFb(key: string): string {
    return UFFeedback[key] || "";
  }
  getImgUrl(fileName: string): string {
    return `${assetsUrl}/svg/${fileName}`;
  }
}
