
import { Vue, Component } from "vue-property-decorator";
import Score from "@/components/feedback/components/Score.vue";
import { UFFeedback } from "@/utils/literals/feedback";
import PieChart from "@/components/charts/PieChart.vue";

@Component({
  components: { PieChart },
})
export default class Examples extends Vue {}
